/* Padding */
.style_box__DV_vb.style_padding-xl__ef_Y8 {
  padding: var(--ant-padding-xl);
}

.style_box__DV_vb.style_padding-lg__V1Y44 {
  padding: var(--ant-padding-lg);
}

.style_box__DV_vb.style_padding-md__N7YN3 {
  padding: var(--ant-padding-md);
}

.style_box__DV_vb.style_padding-sm__MurfW {
  padding: var(--ant-padding-sm);
}

.style_box__DV_vb.style_padding-xs__KjwD3 {
  padding: var(--ant-padding-xs);
}

/* Padding Top */
.style_box__DV_vb.style_padding-top-xl__Hiekt {
  padding-top: var(--ant-padding-xl);
}

.style_box__DV_vb.style_padding-top-lg__v5D_2 {
  padding-top: var(--ant-padding-lg);
}

.style_box__DV_vb.style_padding-top-md__ZiYdk {
  padding-top: var(--ant-padding-md);
}

.style_box__DV_vb.style_padding-top-sm__BJwGS {
  padding-top: var(--ant-padding-sm);
}

.style_box__DV_vb.style_padding-top-xs__D0T9B {
  padding-top: var(--ant-padding-xs);
}

/* Padding Right */
.style_box__DV_vb.style_padding-right-xl__EIssu {
  padding-right: var(--ant-padding-xl);
}

.style_box__DV_vb.style_padding-right-lg__UUmot {
  padding-right: var(--ant-padding-lg);
}

.style_box__DV_vb.style_padding-right-md__DbMGX {
  padding-right: var(--ant-padding-md);
}

.style_box__DV_vb.style_padding-right-sm__YMK0l {
  padding-right: var(--ant-padding-sm);
}

.style_box__DV_vb.style_padding-right-xs__BElPY {
  padding-right: var(--ant-padding-xs);
}

/* Padding Bottom */
.style_box__DV_vb.style_padding-bottom-xl__sw68X {
  padding-bottom: var(--ant-padding-xl);
}

.style_box__DV_vb.style_padding-bottom-lg__StZMe {
  padding-bottom: var(--ant-padding-lg);
}

.style_box__DV_vb.style_padding-bottom-md__AOzzR {
  padding-bottom: var(--ant-padding-md);
}

.style_box__DV_vb.style_padding-bottom-sm__Rw88F {
  padding-bottom: var(--ant-padding-sm);
}

.style_box__DV_vb.style_padding-bottom-xs__wC2Wd {
  padding-bottom: var(--ant-padding-xs);
}

/* Padding Left */
.style_box__DV_vb.style_padding-left-xl__H4ryI {
  padding-left: var(--ant-padding-xl);
}

.style_box__DV_vb.style_padding-left-lg__uI_1y {
  padding-left: var(--ant-padding-lg);
}

.style_box__DV_vb.style_padding-left-md__8eszA {
  padding-left: var(--ant-padding-md);
}

.style_box__DV_vb.style_padding-left-sm__iTMtH {
  padding-left: var(--ant-padding-sm);
}

.style_box__DV_vb.style_padding-left-xs__jbpf2 {
  padding-left: var(--ant-padding-xs);
}

/* Padding Vertical */
.style_box__DV_vb.style_padding-vertical-xl__GHMrx {
  padding-top: var(--ant-padding-xl);
  padding-bottom: var(--ant-padding-xl);
}

.style_box__DV_vb.style_padding-vertical-lg__zBQCf {
  padding-top: var(--ant-padding-lg);
  padding-bottom: var(--ant-padding-lg);
}

.style_box__DV_vb.style_padding-vertical-md__zigw_ {
  padding-top: var(--ant-padding-md);
  padding-bottom: var(--ant-padding-md);
}

.style_box__DV_vb.style_padding-vertical-sm__A2uXc {
  padding-top: var(--ant-padding-sm);
  padding-bottom: var(--ant-padding-sm);
}

.style_box__DV_vb.style_padding-vertical-xs__1L1To {
  padding-top: var(--ant-padding-xs);
  padding-bottom: var(--ant-padding-xs);
}

/* Padding Horizontal */
.style_box__DV_vb.style_padding-horizontal-xl__qaILM {
  padding-right: var(--ant-padding-xl);
  padding-left: var(--ant-padding-xl);
}

.style_box__DV_vb.style_padding-horizontal-lg__gaZH4 {
  padding-right: var(--ant-padding-lg);
  padding-left: var(--ant-padding-lg);
}

.style_box__DV_vb.style_padding-horizontal-md__BHFfG {
  padding-right: var(--ant-padding-md);
  padding-left: var(--ant-padding-md);
}

.style_box__DV_vb.style_padding-horizontal-sm__FH9u4 {
  padding-right: var(--ant-padding-sm);
  padding-left: var(--ant-padding-sm);
}

.style_box__DV_vb.style_padding-horizontal-xs__J8OrK {
  padding-right: var(--ant-padding-xs);
  padding-left: var(--ant-padding-xs);
}

/* 
 * Export breakpoints values.
 *
 * We need to export breakpoint values this way because CSS variables don't work in media query declarations.
 * 
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties#
 * @see https://github.com/css-modules/css-modules/issues/75
*/

.style_container__s9_0y {
  margin: 0 auto;
  width: 100%;
}

.style_container__s9_0y.style_xs__KWRsn {
  max-width: 480px;
}

.style_container__s9_0y.style_sm___rpxe {
  max-width: 576px;
}

.style_container__s9_0y.style_md__Cml1F {
  max-width: 768px;
}

.style_container__s9_0y.style_lg__rVlyI {
  max-width: 1200px;
}

.style_container__s9_0y.style_xl__EKU7t {
  max-width: 1600px;
}

.ant-affix {
  position: fixed;
  z-index: 10;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
  background: #fff;
}
.ant-layout,
.ant-layout * {
  box-sizing: border-box;
}
.ant-layout.ant-layout-has-sider {
  flex-direction: row;
}
.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  width: 0;
}
.ant-layout-header,
.ant-layout-footer {
  flex: 0 0 auto;
}
.ant-layout-header {
  height: 64px;
  padding: 0 50px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 64px;
  background: white;
}
.ant-layout-footer {
  padding: 24px 50px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  background: white;
}
.ant-layout-content {
  flex: auto;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
}
.ant-layout-sider {
  position: relative;
  /* fix firefox can't set width smaller than content on flex item */
  min-width: 0;
  background: white;
  transition: all 0.2s;
}
.ant-layout-sider-children {
  height: 100%;
  margin-top: -0.1px;
  padding-top: 0.1px;
}
.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
  width: auto;
}
.ant-layout-sider-has-trigger {
  padding-bottom: 48px;
}
.ant-layout-sider-right {
  order: 1;
}
.ant-layout-sider-trigger {
  position: fixed;
  bottom: 0;
  z-index: 1;
  height: 48px;
  color: #fff;
  line-height: 48px;
  text-align: center;
  background: #002140;
  cursor: pointer;
  transition: all 0.2s;
}
.ant-layout-sider-zero-width > * {
  overflow: hidden;
}
.ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 64px;
  right: -36px;
  z-index: 1;
  width: 36px;
  height: 42px;
  color: #fff;
  font-size: 18px;
  line-height: 42px;
  text-align: center;
  background: white;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-layout-sider-zero-width-trigger::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  transition: all 0.3s;
  content: '';
}
.ant-layout-sider-zero-width-trigger:hover::after {
  background: rgba(255, 255, 255, 0.1);
}
.ant-layout-sider-zero-width-trigger-right {
  left: -36px;
  border-radius: 5px 0 0 5px;
}
.ant-layout-sider-light {
  background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-trigger {
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
}
.ant-layout-rtl {
  direction: rtl;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.style_layout__TYe0i {
  min-height: 100vh;
}

.style_header__WVfNa {
  border-bottom: 1px solid var(--ant-color-border);
  padding: 0 24px;
  line-height: inherit;
  display: flex;
  align-items: center;
}

.style_footer__RovW3 {
  display: flex;
  align-items: center;
  padding: 0 24px;
  height: 64px; /* Albeit both header and footer have a height of 64px, Antd only sets height on the header. The footer's height is achieved by adding a vertical padding of 24px and a content of 16px. */
}

.style_brand__1omIJ {
  display: block;
  margin-bottom: -10px; /* Because of the descending "y". */
}

.style_brand__1omIJ.style_collapsed__k50IJ {
  margin-bottom: 0;
}

.style_brand__1omIJ.style_middle__fTkVE {
  height: 36px;
}

.style_brand__1omIJ.style_large__IoaxL {
  height: 64px;
}

.style_brand__1omIJ svg {
  height: 100%;
}

