.layout {
  min-height: 100vh;
}

.header {
  border-bottom: 1px solid var(--ant-color-border);
  padding: 0 24px;
  line-height: inherit;
  display: flex;
  align-items: center;
}

.footer {
  display: flex;
  align-items: center;
  padding: 0 24px;
  height: 64px; /* Albeit both header and footer have a height of 64px, Antd only sets height on the header. The footer's height is achieved by adding a vertical padding of 24px and a content of 16px. */
}
